import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    requestPackage : null,
    requestSuccess : ['data']
})

export const PackageTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    data: []
})

/* ------------- Reducers ------------- */
export const success = (state, { data }) => {
    return state.merge({ data: data })
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.REQUEST_SUCCESS]: success
})
import { call, put } from 'redux-saga/effects'
import NewsActions from '../Redux/NewsRedux'

export function* requestNews(api) {

  console.log('====== get news')
  const response = yield call(api.getNews)

  // console.log(response)

  if (response.ok) {
    console.log(response.data)
    let news = response.data.filter(e => e.active == true)
    // let use = ''
    // // console.log(news.length)
    // if (news.length >= 1) {
    //   if (news[news.length - 1]['image']) {
    //     use = news[news.length - 1]['image']['url']
    //     let url = "http://localhost:1337/".concat(use)
    //     use = "<img style='width:100%' src=" + url + " />"

    //   } else {
    //     use = news[news.length - 1]['text']

    //   }
    // } else {
    //   use = ''
    // }


    yield put(NewsActions.requestNewsSuccess(news))
  }
}

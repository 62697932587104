import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import iphon from '../assets/images/Siam-Exchange-iPhoneX.png';
import google from '../assets/images/google-icon.png';
import app from '../assets/images/applestore-icon.png';

import AboutUsActions from '../Redux/AboutUsRedux'


import '../Layouts/css/news.css'
class NewsItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(newProps) {

  }

  handleChange = (event) => {

  }

  render() {
    const lang = this.props.lang || 'th'
    return (
      <div className="news-item">
        <Link to={"news/" + this.props.data.id}>
          <div className="row">
            <div className="col-lg-6">
              <img src={'https://admin.siamkk.com' + this.props.data.image.url} style={{ width: '100%' }} />
            </div>
            <div className="col-lg-6">
              <span style={{ fontSize: 17, fontWeight: 'bold' }}>{this.props.data['topic_' + lang]}</span><br />
              <span style={{ fontSize: 10, color: 'gray' }}>News | {this.props.data.created_at}</span><br />
              <span style={{ color: '#222' }}>{this.props.data['intro_' + lang]}</span>
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(NewsItem)


import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getFlashDeal: null,
  getFlashDealSuccess: ['data']
})

export const FlashDealTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: [],
})

/* ------------- Reducers ------------- */

export const success = (state, { data }) => {
  return state.merge({ data })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.GET_FLASH_DEAL_SUCCESS]: success,

})
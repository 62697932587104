import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap';

import { SocialIcon } from 'react-social-icons';
import Form from "react-jsonschema-form";

import '../Layouts/css/footer.css'
import logo from '../assets/images/line_icon.png'
import ContactActions from '../Redux/ContactRedux'
const socialIconStyle = { height: 30, width: 30, }

const schema = {
  // title: "Create New Project",
  type: "object",
  required: ["name", "email", "subject", "body"],
  properties: {
    name: { type: "string", title: "ชื่อ-นามสกุล", default: "" },
    email: { type: "string", title: "อีเมล", default: "" },
    subject: { type: "string", title: "หัวข้อเรื่อง", default: "" },
    body: { type: "string", title: "ข้อความ", default: "" }
  }
};


class Footer2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.props.getContact()
  }

  componentWillReceiveProps(newProps) {

  }

  handleChange = (event) => {

  }

  render() {
    console.log(this.props.data)
    return (
      <div className="footer">
        <div className="container">
          {/* <div style={{ flexDirection: 'row', display: 'flex', height: 400, color: 'black' }}> */}
          <Row style={{ paddingBottom: 20 }}>
            <Col md={4}>
              <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
                <h4>ติดตามเรา</h4>
                <div className='social'>
                  <div style={{ width: 30, height: 30, borderRadius: 15, margin: '10px 20px 10px 0px', backgroundColor: 'white' }}>
                    <SocialIcon network="facebook" style={socialIconStyle} />
                  </div>

                  <div className='social-detail'>
                    <span className='social-name'>Facebook</span>
                    <span className='social-account'>
                      {this.props.data ? this.props.data.facebook : ''}
                    </span>
                  </div>

                </div>
                <div className='social'>
                  <div style={{ width: 30, height: 30, borderRadius: 15, margin: '10px 20px 10px 0px', backgroundColor: 'white' }}>
                    <img src={logo} style={socialIconStyle} />
                  </div>
                  <div className='social-detail'>
                    <span className='social-name'>Line</span>
                    <span className='social-account'>
                      {this.props.data ? this.props.data.line : ''}
                    </span>
                  </div>
                </div>

              </div>
            </Col>
            <Col md={4}>
              {/* <div style={{ flex: 1 }}> */}
              <h4>ข้อมูลการติดต่อ</h4>
              <div className='social-detail'>
                <span className='social-name'>ที่อยู่</span>
                <span className='social-account'>
                  {this.props.data ? this.props.data.address : ''}
                </span>
              </div>


              <div className='social-detail'>
                <span className='social-name'>อีเมล</span>
                <span className='social-account'>
                  {this.props.data ? this.props.data.email : ''}
                </span>
              </div>
            </Col>
            <Col md={4}>
              {/* <div style={{ flex: 1 }}> */}
              <h4 style={{ color: 'transparent' }}>.</h4>
              <div className='social-detail'>
                <span className='social-name'>โทรศัพท์</span>
                <span className='social-account' dangerouslySetInnerHTML={{ __html: this.props.data ? this.props.data.tel : '' }} />
              </div>
              {/* <Form schema={schema}
                onSubmit={() => this.onSubmit}
                onError={() => console.log("errors")}
              /> */}
            </Col>

          </Row>
        </div >
        <p className="copyright">Copyright © 2018. SIAM EXCHANGE KK All rights reserved.</p>
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.contact.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({
  getContact: () => dispatch(ContactActions.requestContact()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer2)


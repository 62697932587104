import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap';
import iphon from '../assets/images/Siam-Exchange-iPhoneX.png';
import google from '../assets/images/google-icon.png';
import app from '../assets/images/applestore-icon.png';

// import AboutUsActions from '../Redux/AboutUsRedux'
import NewsAction from '../Redux/NewsRedux'


import '../Layouts/css/news.css'
import NewsItem from './NewsItem';

import img1 from '../assets/images/news/img1.png'
import img2 from '../assets/images/news/img2.png'
import img3 from '../assets/images/news/img3.png'
import img4 from '../assets/images/news/img4.png'

class News extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.props.getNews()
  }

  componentWillReceiveProps(newProps) {
    // if (this.props.data != newProps.data) {
    //   console.log(newProps.data)
    // }
  }
  handleChange = (event) => {
    // this.props.set(event.target.value)
  }

  render() {

    // let content_aboutus = this.props.data && this.props.data.length > 0 ? JSON.parse(this.props.data[0]['content_aboutus']) : { en: '', th: '', zh: '' }

    // let head_aboutus = this.props.data && this.props.data.length > 0 ?
    //   JSON.parse(this.props.data[0]['head_aboutus']) : { en: '', th: '', zh: '' }
    // let lang = this.props.lang
    let news = [
      {
        id: 1,
        img: img1,
        title: 'Be a genius money trader',
        date: '2019-04-29',
        text: 'Praesent facilisis dolor in nunc interdum blandit. Quisque hendrerit pellentesque sem vitae porta.'
      },
      {
        id: 2,
        img: img2,
        title: 'Popular currency in 2019',
        date: '2019-04-18',
        text: 'Praesent facilisis dolor in nunc interdum blandit. Quisque hendrerit pellentesque sem vitae porta.'
      },
      {
        id: 3,
        img: img3,
        title: 'Making decision on the right time!',
        date: '2019-04-18',
        text: 'Praesent facilisis dolor in nunc interdum blandit. Quisque hendrerit pellentesque sem vitae porta.'
      },
      {
        id: 4,
        img: img4,
        title: 'Be a genius money trader',
        date: '2019-04-25',
        text: 'Praesent facilisis dolor in nunc interdum blandit. Quisque hendrerit pellentesque sem vitae porta.'
      }
    ]
    news = this.props.data || []
    // console.log(this.props.data)
    news = JSON.parse(JSON.stringify(news))
    news = news.reverse()
    let menu = {
      name_menu: {
        en: 'NEWS',
        th: 'ข่าวสาร'
      }
    }

    menu = menu['name_menu'][this.props.lang]
    // console.log(this.props.lang)

    return (
      <div className="news">
        <Container id="news">
          <div style={{ width: '100%' }}>
            <h2>{menu}</h2>
            <div className="subtitle">Fresh news everyday</div>
          </div>
          <div className="row">
            {
              news.map((e, i) => {
                return <div className="col-md-6" key={i}>
                  <NewsItem data={e} lang={this.props.lang} />
                </div>
              })
            }
          </div>
          {/* <h1 dangerouslySetInnerHTML={{ __html: head_aboutus[lang] }} />
          <div className="row">
            <div className="col-lg-6 col-md-6 contentabout" dangerouslySetInnerHTML={{ __html: content_aboutus[lang] }} />

            <div className="col-lg-6 col-md-6 pic">
              <img src={iphon} style={{ paddingBottom: 50 }} height={300} />
              <Row >
                <a href="https://play.google.com/store/apps/details?id=com.siamexchange" className="pic2" style={{ marginRight: -50 }} >
                  <img src={google} className="inapp" />
                </a>
                <a href="#" className="pic2">
                  <img src={app} className="inapp" />
                </a>
              </Row>
            </div>
          </div> */}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.news.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({
  getNews: () => dispatch(NewsAction.requestNews()),
  // set: (text) => dispatch(AboutUsActions.textAboutUs(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(News)


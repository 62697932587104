import apisauce from 'apisauce'

let b
// if (process.env.NODE_ENV === 'production') {
// b = 'http://167.99.72.166:1337/'
// } else {
b = 'https://admin.siamkk.com/'
// b = 'http://localhost:1337/'
// }

const create = (baseURL = b) => {

  const api = apisauce.create({
    baseURL,
    headers: {
      // 'Cache-Control': 'no-cache',
      // 'Access-Control-Allow-Origin': '*'
    },
    timeout: 10000
  })

  //รวม function Api
  const getCurrency = () => api.get('currencies')
  const getAboutUs = () => api.get('about-uses')
  const getContact = () => api.get('contacts')
  const getBanner = () => api.get('slides')
  const getMenu = () => api.get('menus')
  const getNews = () => api.get('news')
  const getFlashDeal = () => api.get('flashdeals')

  return {
    getCurrency,
    getAboutUs,
    getContact,
    getBanner,
    getMenu,
    getNews,
    getFlashDeal
  }
}

// let's return back our create method as the default.
export default {
  create
}

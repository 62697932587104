import { call, put } from 'redux-saga/effects'
import CurrencyActions from '../Redux/CurrencyRedux'
import CalculateActions from '../Redux/CalculateRedux'

export function* requestCurrency(api) {
  const response = yield call(api.getCurrency)

  console.log(response)

  if (response.ok) {
    yield put(CurrencyActions.requestCurrencySuccess(response.data))
    if (response.data && response.data.length > 0) {
      yield put(CalculateActions.setRate(response.data[0].buying))
    }
  }
}

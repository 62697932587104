import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  requestAboutUs: null,
  requestAboutUsSuccess: ['data'],

  textAboutUs: ['text']
})

export const AboutUsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  aboutus: '',
  data: [],
  text: '{ "th": "", "en": "", "zh": "" }'
})

/* ------------- Reducers ------------- */

export const success = (state, { data }) => {
  return state.merge({ data: data })
}

export const aboutustext = (state, { text }) => {
  return state.merge({ text: text })
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.REQUEST_ABOUT_US_SUCCESS]: success,
  [Types.TEXT_ABOUT_US]: aboutustext

})
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    requestContact: null,
    requestContactSuccess: ['data'],

    textContact : ['text']
})

export const ContactTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  contact: '',
  data: [],
  text: 'th'
})

/* ------------- Reducers ------------- */

export const success = (state, { data }) => {
    return state.merge({ data: data })
}

export const contacttext = (state, { text }) => {
  return state.merge({ text: text })
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.REQUEST_CONTACT_SUCCESS]: success,
  [Types.TEXT_CONTACT]: contacttext
  
})
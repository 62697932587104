import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap';
import iphon from '../assets/images/Siam-Exchange-iPhoneX.png';
import google from '../assets/images/google-icon.png';
import app from '../assets/images/applestore-icon.png';

import AboutUsActions from '../Redux/AboutUsRedux'


import '../Layouts/css/footer.css'
class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(newProps) {

  }

  handleChange = (event) => {

  }

  render() {
    return (
      <div className="footer">
        <div className="head">
          SIAM EXCHANGE KK
        </div>
        <div className="body">
          <Container>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <h5>SIAM EXCHANGE KK</h5>
                <div className="small">Cras dignissim vitae quam id vulputate. Phaseilus (Short information) laoreet mollis. Etiam maximus placerat diam, quis hendrerit turpis bibendum congue.</div>
              </div>
              <div className="col-lg-3 col-md-6">
                <h5>LATEST NEWS</h5>
                <div className="small">
                  Cras dignissim vitae quam id vulputate.<br />
                  July 12, 2016
                  <hr />
                  Phaseilus (Short information) laoreet mollis.<br />
                  July 12, 2016
                  <hr />
                  Etiam maximus placerat diam, quis hendrerit turpis bibendum congue.<br />
                  July 12, 2016
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <h5>QUICK LINKS</h5>
                <a href="#">FAQ</a><br />
                <a href="#">Job opportunities</a><br />
                <a href="#">Staffs sectoin</a><br />
                <a href="#">Internal line</a><br />
              </div>
              <div className="col-lg-3 col-md-6">
                <h5>ARCHIVES</h5>
                <a href="#">April 2019</a><br />
                <a href="#">March 2019</a><br />
                <a href="#">February 2019</a><br />
                <a href="#">Januaray 2019</a><br />
              </div>
            </div>
          </Container>
        </div>
        <p className="copyright">Copyright © 2018. SIAM EXCHANGE KK All rights reserved.</p>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Footer)


import { call, put } from 'redux-saga/effects'
import PackageActions from '../Redux/PackageRedux'

export function* requestPackage(api) {

  const response = yield call(api.getPackage)

  console.log(response)

  if (response.ok) {
    yield put(PackageActions.requestSuccess(response.data))
    //     // yield * signupAtFirebase(getFirebase, email, response.data.access_token)
  } else {
    //     // let message = JSON.parse(response.data.message)
    //     // yield put(AuthActions.signupFailure(message))
  }
}

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    requestMenu: null,
    requestMenuSuccess: ['data'],

})

export const MenuTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  menu: '',
  data: [],
})

/* ------------- Reducers ------------- */

export const success = (state, { data }) => {
    return state.merge({ data: data })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_MENU_SUCCESS]: success,
  
})
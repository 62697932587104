import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap';
import ContactActions from '../Redux/ContactRedux'

import { strictEqual } from 'assert';

import '../Layouts/css/contact.css'
class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.props.getContact()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.data != newProps.data) {
      console.log(newProps.data)
    }
  }
  downloadMap = () => {

    if (this.props.lang == 'th') {
      return "ดาวน์โหลดแผนที่"
    } else {
      return "DownloadMap"
    }
  }

  render() {

    console.log(this.props.data)

    let {
      head_contact, head_officehour, name, address,
      email, faxnumber, officehour, phonenumber
    } = this.props.data && this.props.data.length > 0 ?
        this.props.data[0] : {}

    head_contact = head_contact ? JSON.parse(head_contact) : ''
    head_officehour = head_contact ? JSON.parse(head_officehour) : ''
    name = name ? JSON.parse(name) : ''
    address = address ? JSON.parse(address) : ''
    email = email ? JSON.parse(email) : ''
    faxnumber = faxnumber ? JSON.parse(faxnumber) : ''
    officehour = officehour ? JSON.parse(officehour) : ''
    phonenumber = phonenumber ? JSON.parse(phonenumber) : ''
    var lang = this.props.lang

    return (
      <Container className="contact" id="footer">

        <div className="footer" >

          <div style={{ flex: 5, marginBottom: 20 }}>
            <div dangerouslySetInnerHTML={{ __html: name[lang] }} />
            <div dangerouslySetInnerHTML={{ __html: address[lang] }} style={{ paddingTop: 20 }} />
            <a target="_blank" style={{ color: "white", textDecoration: 'underline' }} href="http://siamexchange.co.th/images/SE_Map.jpg">{this.downloadMap()}</a>
          </div>

          <div style={{ flex: 4, marginBottom: 20 }}>
            <div dangerouslySetInnerHTML={{ __html: head_officehour[lang] }} />
            <div dangerouslySetInnerHTML={{ __html: officehour[lang] }} style={{ paddingTop: 20 }} />
          </div>

          <div style={{ flex: 3, marginBottom: 20 }}>
            <div dangerouslySetInnerHTML={{ __html: head_contact[lang] }} />

            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ flex: 1.5, width: 33.4 }}>
                <img src="http://siamexchange.co.th/images/contact-phone.png" style={{ marginTop: 23, height: 20, width: 20 }} alt="" />
              </div>
              <div style={{ flex: 10 }}>
                <div dangerouslySetInnerHTML={{ __html: phonenumber[lang] }} style={{ paddingTop: 20 }} />
              </div>
            </div>

            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ flex: 1.5, width: 33.4 }}>
                <img src="http://siamexchange.co.th/images/contact-fax.png" style={{ marginTop: 3, height: 20, width: 20 }} alt="" />
              </div>
              <div style={{ flex: 10 }}>
                <div dangerouslySetInnerHTML={{ __html: faxnumber[lang] }} />
              </div>
            </div>

            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div style={{ flex: 1.5, width: 33.4 }}>
                <img src="http://siamexchange.co.th/images/contact-mail.png" style={{ marginTop: 3, height: 15, width: 20, }} alt="" />
              </div>
              <div style={{ flex: 10 }}>
                <div className="contact-mail" dangerouslySetInnerHTML={{ __html: email[lang] }} />
              </div>

            </div>


          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.contact.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({
  getContact: () => dispatch(ContactActions.requestContact()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact)


import React, { Component } from 'react'
import ScrollUpButton from 'react-scroll-up-button';

import { connect } from 'react-redux'

import AboutUs from '../Components/AboutUs'
import Currency from '../Components/Currency'
import Calculate from '../Components/Calculate'
import Banner from '../Components/Banner'
import Address from '../Components/Address'
import Centered from '../Components/Centered'
import Footer from '../Components/Footer'

import '../Layouts/css/news.css'
import ReactGA from 'react-ga';
import NewsItem from '../Components/NewsItem';

import img1 from '../assets/images/news/img1.png'
import img2 from '../assets/images/news/img2.png'
import img3 from '../assets/images/news/img3.png'
import img4 from '../assets/images/news/img4.png'


class NewsItemPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      headerHeight: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.initializeReactGA()
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  initializeReactGA() {
    ReactGA.initialize('UA-135962750-1');
    ReactGA.pageview('/');
  }

  checkAuth() {

  }

  render() {

    let news = [
      {
        id: 1,
        img: img1,
        title: 'Be a genius money trader',
        date: '2019-04-29',
        text: 'Praesent facilisis dolor in nunc interdum blandit. Quisque hendrerit pellentesque sem vitae porta.'
      },
      {
        id: 2,
        img: img2,
        title: 'Popular currency in 2019',
        date: '2019-04-18',
        text: 'Praesent facilisis dolor in nunc interdum blandit. Quisque hendrerit pellentesque sem vitae porta.'
      },
      {
        id: 3,
        img: img3,
        title: 'Making decision on the right time!',
        date: '2019-04-18',
        text: 'Praesent facilisis dolor in nunc interdum blandit. Quisque hendrerit pellentesque sem vitae porta.'
      },
      {
        id: 4,
        img: img4,
        title: 'Be a genius money trader',
        date: '2019-04-25',
        text: 'Praesent facilisis dolor in nunc interdum blandit. Quisque hendrerit pellentesque sem vitae porta.'
      }
    ]

    console.log(this.props.match.params.id)
    news = this.props.data

    let data = news.filter(e => e.id == this.props.match.params.id)
    data = data.length > 0 ? data[0] : {}
    console.log(data)

    const lang = this.props.lang || 'th'
    return (
      <div className="news">
        <div className="news">
          <div className="news">
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontSize: 20, fontWeight: 'bold' }}>{data['topic_' + lang]}</span><br />
              <span style={{ fontSize: 10, color: 'gray' }}>News | {data.created_at}</span><br />
            </div>
            <div className="news-item" style={{
              margin: this.state.windowWidth <= 768 && this.state.windowWidth >= 576 ? -50 :
                (this.state.windowWidth < 576 ? -75 : 0)
            }}>
              <div className="row">
                <div className="col-lg-4">
                  {
                    data && data.image &&
                    <img src={'https://admin.siamkk.com' + data.image.url} style={{ width: '100%' }} />
                  }

                </div>
                <div className="col-lg-8">
                  <div dangerouslySetInnerHTML={{ __html: data['text_' + lang] }} />
                  {/* <p style={{ color: '#222' }}>{} */}
                  {/* </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  data: state.news.data,
  lang: state.language.language
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(NewsItemPage)

import { call, put } from 'redux-saga/effects'
import FlashDealActions from '../Redux/FlashDealRedux'

export function* getFlashDeal(api) {
  const response = yield call(api.getFlashDeal)

  console.log(response)

  if (response.ok) {
    yield put(FlashDealActions.getFlashDealSuccess(response.data))
  }
}

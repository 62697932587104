import React, { Component } from 'react'
import { connect } from 'react-redux'
import img from '../assets/images/siamexchange.jpg';
import { Container, Row, Col } from 'reactstrap';

import '../Layouts/css/address.css'


class Address extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  static defaultProps = {
    center: {
      lat: 13.747100,
      lng: 100.530716
    },
    zoom: 11
  }

  componentWillReceiveProps(newProps) {
    if (this.props.data != newProps.data) {
      console.log(newProps.data)
    }
  }

  render() {
    let menu = this.props.menu && this.props.menu.length ?
      JSON.parse(this.props.menu[4]['name_menu'])[this.props.lang] : ''
    // var menu = JSON.parse(this.props.menu[4]['name_menu'])
    return (
      <Row>
        <Col className="map">
          <div className="resp-container">
            <iframe className="resp-iframe" id="gmap_canvas" src="https://maps.google.com/maps?q=siam%20exchange%20kk&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
            </iframe>
          </div>

        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  menu: state.menu.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Address)


import { call, put } from 'redux-saga/effects'
import MenuActions from '../Redux/MenuRedux'

export function* requestMenu(api) {
  const response = yield call(api.getMenu)

  console.log(response)

  if (response.ok) {
    yield put(MenuActions.requestMenuSuccess(response.data))
  }
}

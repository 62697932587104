import React, { Component } from 'react'
import ScrollUpButton from 'react-scroll-up-button';

import { connect } from 'react-redux'

import AboutUs from '../Components/AboutUs'
import Currency from '../Components/Currency'
import Calculate from '../Components/Calculate'
import Banner from '../Components/Banner'
import Address from '../Components/Address'
import Centered from '../Components/Centered'
import Footer from '../Components/Footer'

import '../Layouts/css/converter.css'
import ReactGA from 'react-ga';
import News from '../Components/News';


class Converter extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
    this.initializeReactGA()
  }

  initializeReactGA() {
    ReactGA.initialize('UA-135962750-1');
    ReactGA.pageview('/');
  }

  checkAuth() {

  }

  render() {

    return (

      <div className="converter">
        <Calculate />
      </div>

    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Converter)

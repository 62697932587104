import React, { Component } from 'react';

import './Styles/App.css';
import { connect } from 'react-redux'
import { Switch, withRouter } from 'react-router'
import { Route } from 'react-router-dom'

import MainLayout from './Layouts/MainLayout'
import Loading from './Layouts/Loading'

// import Signin from './Containers/Signin'
// import Signup from './Containers/Signup'
import Dashboard from './Containers/Dashboard'
import Popup from 'react-popup';
import Converter from './Containers/Converter';
import Rates from './Containers/Rates';
import NewsPage from './Containers/NewsPage';
import NewsItemPage from './Containers/NewsItemPage';
import AboutUsPage from './Containers/AboutUsPage';

import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

import ReactGA from 'react-ga';
// import auth from './auth.ts'; // Sample authentication provider

const trackingId = "UA-158883982-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class AppRoute extends Component {
  render() {
    return (
      <div>

        <Popup
          className="mm-popup"
          btnClass="mm-popup__btn"
          closeBtn={true}
          closeHtml={null}
          defaultOk="Ok"
          defaultCancel="Cancel"
          // wildClasses={false}
          escToClose={true} />
        <Router history={history}>
          <Switch>
            {/* <Route exact path="/" component={Loading} /> */}
            {/* <Route path="/signin" component={Signin} /> */}
            {/* <Route path="/signup" component={Signup} /> */}
            <MainLayout path="/" exact component={Dashboard} />
            <MainLayout path="/converter" component={Converter} />
            <MainLayout path="/rates" component={Rates} />
            <MainLayout path="/news" exact component={NewsPage} />
            <MainLayout path="/news/:id" exact component={NewsItemPage} />
            <MainLayout path="/aboutus" component={AboutUsPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
})

// export default layout
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRoute))

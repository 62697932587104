import React, { Component } from 'react'
import { connect } from 'react-redux'


class FlashDeal extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.data)
  }

  handleChange = (event) => {

  }

  render() {
    console.log(this.props.data)
    return (
      <div style={{ backgroundColor: 'darkorange' }}>
        {
          this.props.data && this.props.data.map(e => {
            return <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ flex: 1 }}></span>
                <span style={{ flex: 1, color: 'white', fontSize: 25 }}>Buying</span>
                <span style={{ flex: 1, color: 'white', fontSize: 25 }}>Selling</span>
              </div>
              <hr />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ flex: 1, color: 'white', fontSize: 25 }}>{e.currency.currency}</span>
                <span style={{ flex: 1, color: 'white', fontSize: 25 }}>{e.buying}</span>
                <span style={{ flex: 1, color: 'white', fontSize: 25 }}>{e.selling}</span>
              </div>
            </div>
          })
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.flashdeal.data
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(FlashDeal)


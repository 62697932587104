import React, { Component } from 'react'
import { connect } from 'react-redux'
import { render } from "react-dom";
import Modal from "react-responsive-modal";
import NewsActions from '../Redux/NewsRedux'

import logo from '../assets/images/logo_transbg250.png'

class Centered extends Component {
  state = {
    open: this.props.news == '' ? false : true
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.news != nextProps.news) {
      if (nextProps.news == '') {
        this.setState({ open: false })
      } else {
        this.setState({ open: true })
      }
    }
  }

  componentDidMount() {
    this.props.getNews()
  }

  onCloseModal = () => {
    this.setState({ open: false });

  };

  renderSwitch = (open) => {
    switch (open) {
      case true:
        return <Modal
          open={open}
          onClose={this.onCloseModal}
          center
          closeIconSize={20}
          styles={{ modal: { borderRadius: 10 }, closeButton: { borderRadius: 12, border: '2px solid #000' } }}
        >
          <img src={logo} style={{ height: 40 }} />
          <hr style={{ marginBottom: 5 }} />
          <div className="imgModal" style={{ paddingTop: 20, wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: this.props.news }} />
        </Modal>

      default: ;

    }
  }
  render() {
    console.log(this.props.news == '')

    return (
      <div >
        {this.renderSwitch(this.state.open)}

      </div>
    );
  }
}


const mapStateToProps = state => ({
  news: state.news.data
})

const mapDispatchToProps = dispatch => ({
  getNews: () => dispatch(NewsActions.requestNews()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Centered)
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    requestAddress: null,
    requestAddressSuccess: ['data'],

    textAddress : ['text']
})

export const AddressTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    address: '',
    data: [],
    text: 'th'
})

/* ------------- Reducers ------------- */

export const success = (state, { data }) => {
    return state.merge({ data: data })
}

export const addresstext = (state, { text }) => {
  return state.merge({ text: text })
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.REQUEST_ADDRESS_SUCCESS]: success,
  [Types.TEXT_ADDRESS]: addresstext
  
})
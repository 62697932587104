import { takeLatest, all, take, takeEvery } from 'redux-saga/effects'
import API from '../Services'
// import { getFirebase } from 'react-redux-firebase'

/* ------------- Types ------------- */
import { AboutUsTypes } from '../Redux/AboutUsRedux' //มาจาก redux
import { PackageTypes } from '../Redux/PackageRedux'
import { CurrencyTypes } from '../Redux/CurrencyRedux'
import { CalculateTypes } from '../Redux/CalculateRedux'
import { ContactTypes } from '../Redux/ContactRedux'
import { BannerTypes } from '../Redux/BannerRedux'
import { MenuTypes } from '../Redux/MenuRedux'
import { NewsTypes } from '../Redux/NewsRedux'
import { FlashDealTypes } from '../Redux/FlashDealRedux'

/* ------------- Sagas ------------- */
import { requestAboutUs } from './AboutUsSagas'
import { requestPackage } from './PackageSagas'      // มาจาก sagas
import { requestCurrency } from './CurrencySagas'
import { requestContact } from './ContactSagas'
import { changeType, changeCurrency, changeValue } from './CalculateSagas'
import { requestBanner } from './BannerSagas'
import { requestMenu } from './MenuSagas'
import { requestNews } from './NewsSagas'
import { getFlashDeal } from './FlashDealSagas'

/* ------------- API ------------- */

const WebApi = API.Web.create()                      //มาจาก Api Index

/* ------------- Connect Types To Sagas ------------- */

export default function* root(context = {}) {
    yield all([
        takeLatest(AboutUsTypes.REQUEST_ABOUT_US, requestAboutUs, WebApi),
        takeLatest(PackageTypes.REQUEST_PACKAGE, requestPackage, WebApi),
        takeLatest(NewsTypes.REQUEST_NEWS, requestNews, WebApi),

        takeLatest(CurrencyTypes.REQUEST_CURRENCY, requestCurrency, WebApi),

        takeLatest(CalculateTypes.CHANGE_TYPE, changeType),
        takeLatest(CalculateTypes.CHANGE_CURRENCY, changeCurrency),
        takeLatest(CalculateTypes.CHANGE_VALUE, changeValue),

        takeLatest(ContactTypes.REQUEST_CONTACT, requestContact, WebApi),
        takeLatest(MenuTypes.REQUEST_MENU, requestMenu, WebApi),
        takeLatest(BannerTypes.REQUEST_BANNER, requestBanner, WebApi),
        takeLatest(NewsTypes.REQUEST_NEWS, requestNews, WebApi),

        takeLatest(FlashDealTypes.GET_FLASH_DEAL, getFlashDeal, WebApi),
    ])
}
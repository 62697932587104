import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  valueCurrency: ['currency'],
  typeToCal: ['extype'],
  calculate: ['cal'],
  bathChange: ['bath'],

  changeType: ['t'],
  changeCurrency: ['currency'],
  changeValue: ['value'],
  setRate: ['rate']
})

export const CalculateTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  currency: 'USD $ 1',
  extype: 'buying',
  rate: '',

  cal: null,
  bath: null
})

/* ------------- Reducers ------------- */

export const calculate = (state, { currency }) => {
  return state.merge({ currency })
}

export const typeCalculate = (state, { extype }) => {
  console.log(extype)
  return state.merge({ extype: extype })
}

export const calculateValue = (state, { cal }) => {
  return state.merge({ cal: cal })
}

export const bathValue = (state, { bath }) => {
  return state.merge({ bath: bath })
}

export const setRate = (state, { rate }) => {
  return state.merge({ rate })
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {

  [Types.VALUE_CURRENCY]: calculate,
  [Types.TYPE_TO_CAL]: typeCalculate,
  [Types.CALCULATE]: calculateValue,
  [Types.BATH_CHANGE]: bathValue,
  [Types.SET_RATE]: setRate
})
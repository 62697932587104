import React from 'react'
// import { Route } from 'react-router'
import { Container } from 'reactstrap';
import EmptyLayout from './EmptyLayout'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer2 from '../Components/Footer2'
import ScrollUpButton from 'react-scroll-up-button';
import { withRouter } from 'react-router-dom';


import './css/style.css'
import './css/header.css'

const mainLayout = withRouter(props => <MainLayout {...props} />);

const MainLayout = ({ component: Component, ...rest }) => {
  return (
    <EmptyLayout {...rest} component={matchProps => (
      <div className="app">
        <Header />
        <div className="app-body">
          {/* <Sidebar {...this.props} /> */}
          <main className="main">
            {/* <Breadcrumb /> */}
            {/* <Container> */}
            <Component {...matchProps} />
            {/* </Container> */}
            <Footer2 />

            {/* <Centered /> */}
            <div>
              <ScrollUpButton />
            </div>
          </main>
          {/* <Aside /> */}
        </div>
        {/* <Footer /> */}
      </div>
    )} />
  )
}

export default mainLayout
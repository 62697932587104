import { call, put } from 'redux-saga/effects'
import BannerActions from '../Redux/BannerRedux'

export function* requestBanner(api) {
  const response = yield call(api.getBanner)

  // console.log(response)

  if (response.ok) {
    yield put(BannerActions.requestBannerSuccess(response.data))
  }
}

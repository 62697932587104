import { call, put } from 'redux-saga/effects'
import AboutUsActions from '../Redux/AboutUsRedux'

export function* requestAboutUs(api) {
  const response = yield call(api.getAboutUs)

  console.log(response)

  if (response.ok) {
    yield put(AboutUsActions.requestAboutUsSuccess(response.data))
    //     // yield * signupAtFirebase(getFirebase, email, response.data.access_token)
  } else {
    //     // let message = JSON.parse(response.data.message)
    //     // yield put(AuthActions.signupFailure(message))
  }
}

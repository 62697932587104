import React, { Component } from 'react'
import ScrollUpButton from 'react-scroll-up-button';

import { connect } from 'react-redux'

import AboutUs from '../Components/AboutUs'
import Contact from '../Components/Contact'
import Currency from '../Components/Currency'
import Calculate from '../Components/Calculate'
import Banner from '../Components/Banner'
import Address from '../Components/Address'
import Centered from '../Components/Centered'
import Footer from '../Components/Footer'

import '../Layouts/css/dashboard.css'
import ReactGA from 'react-ga';
import News from '../Components/News';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap'
import FlashDealActions from '../Redux/FlashDealRedux'
import FlashDeal from '../Components/FlashDeal';
import Popup from 'react-popup';

import ThemeImages from '../Themes/Images'
import ReactCountryFlag from "react-country-flag";
import moment from 'moment'


// import 
function formatDate() {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

const FlashDealImage = ({ data }) => {
  // console.log(data)
  let cell = data.currency
  let c = ''
  if (cell.startsWith('RUPEE')) c = 'IN'
  else if (cell == 'RUPIAH') c = 'ID'
  else {
    c = cell.substr(0, 2)
    if (c == 'JY') c = 'JP' // Japan
    else if (c == 'PI') c = 'PH' // Philipines
    else if (c == 'TU') c = 'TR' // Turkey
  }


  let pt = data.show.toUpperCase()
  let tt = ''
  console.log(data)
  if (data.lang == 'th') {
    tt = (pt == 'BUYING' ? 'เงินต่างประเทศ แลกเป็น เงินไทย' : 'เงินไทย แลกเป็น เงินต่างประเทศ')
    pt = (pt == 'BUYING' ? 'ซื้อ' : 'ขาย')

  } else {
    tt = (pt == 'BUYING' ? 'Foreign currencies to Thai baht' : 'Thai baht to foreign currencies')
  }

  return (
    <div style={{ margin: -20, textAlign: 'center', position: 'relative' }}>
      <div style={{
        position: 'absolute', display: 'flex', top: 0, right: 0, left: 0, bottom: 0,
        flexDirection: 'column',
        color: 'white',
        // backgroundColor: 'red'
      }}>
        <div style={{
          display: 'flex', flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          // paddingTop: 80, 
          justifyContent: 'center'
          // backgroundColor: 'white'
        }}>
          {/* <h1>{data.currency}</h1> */}
          <h1 className="currency-header" style={{ WebkitTextStrokeWidth: 1, marginBottom: -10 }}>
            {/* {data.currency_head ? data.currency_head.toUpperCase() : 'SELLING'} */}
            {pt}
          </h1>

          <h4 className="currency-header" style={{
            // marginTop: 10,
            // marginBottom: 30,
            WebkitTextStrokeWidth: 0,
            fontWeight: 'bold',
            letterSpacing: 1,
            width: '11rem',
            color: '#444'
          }}>
            {tt}
          </h4>
        </div>
        <div style={{
          display: 'flex', flex: 1,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div className="currency-content">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ReactCountryFlag
                // styleProps={{
                //   width: '4rem',
                //   height: '4rem'
                // }}
                className="country-icon"
                code={c}
                svg
              />
              <h1 className="currency-text">{data.currency}</h1>
            </div>
            <span className="currency">{data[data.show]}</span>
          </div>
        </div>
      </div>
      <img
        className="mm-popup__image"
        src={ThemeImages.flashDeal2}
        style={{ boxShadow: '2px 2px 8px', borderRadius: 15, maxHeight: '80vh', width: '60vh' }}
      />
    </div>
  )
}

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {

    };
    this.initializeReactGA()
  }

  initializeReactGA() {
    ReactGA.initialize('UA-135962750-1');
    ReactGA.pageview('/');
  }

  checkAuth() {

  }

  componentDidMount() {

    const lang = this.props.lang
    Popup.registerPlugin('flashdeal', function (flashDeal, currencies, callback) {
      let promptValue = null;
      let promptChange = function (value) {
        promptValue = value;
      };

      console.log(flashDeal)

      let crc = {}
      if (currencies) {
        crc = currencies.filter(e => e.currency == flashDeal.currency.currency)
        if (crc.length > 0) {
          crc = crc[0]
        }
      }

      let currency = {
        buying: flashDeal.buying, //crc.buying,
        selling: flashDeal.selling, //crc.selling,
        currency: flashDeal.currency.currency,
        lang: lang,
        show: !flashDeal.Show ? 'buying' : flashDeal.Show.toLowerCase()
      }

      // let currency = { // data demo
      //   BUYING: 30.02,
      //   SELLING: 250,
      //   currency: 'TH',
      //   currency_head: 'Buying',
      //   show: 'BUYING'
      // }

      this.create({
        // title: 'Flash Deal',
        // content: <FlashDeal onChange={promptChange} placeholder={placeholder} value={defaultValue} />,
        content: <FlashDealImage data={currency} />
      });
    });

    this.props.getFlashDeal()
    console.log(this.props.flashdeals)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.flashdeals != nextProps.flashdeals) {

      const today = moment().format("X") //formatDate()
      let fd = nextProps.flashdeals.filter(
        e => e.active &&
          moment(e.start_date).format("X") <= today &&
          moment(e.end_date).format("X") >= today
      )
      // alert('flash deal')
      /** Call the plugin */
      console.log(fd)
      if (fd.length > 0) {

        Popup.plugins().flashdeal(fd[0], this.props.currencies, function (value) {
          Popup.alert('You typed: ' + value);
        });
      }
    }
  }

  render() {
    console.log(this.props)
    return (

      <div className="dashboard">

        <Banner />
        <div style={{ marginTop: 50 }}>
          <Calculate />
        </div>

        <div style={{ backgroundColor: '#f0f0f0', paddingBottom: 40 }}>
          <Currency limit={8} />
          <Container>
            <div style={{ textAlign: 'center' }}><Link to='/rates'><span style={{ color: 'darkorange' }}>See more</span></Link></div>
          </Container>
        </div>

        <div style={{ padding: '40px 0' }}>
          <News />
          <Container>
            <div style={{ textAlign: 'center' }}><Link to='/news'><span style={{ color: 'darkorange' }}>See more</span></Link></div>
          </Container>
        </div>

        {/* <button onClick={() => {
          Popup.plugins().flashdeal('', 'Type your name', function (value) {
            Popup.alert('You typed: ' + value);
          });
        }}>Click</button> */}

        <AboutUs />
        <Address />
        {/* <Contact /> */}

      </div>

    )
  }
}

Dashboard.propTypes = {

}

const mapStateToProps = state => ({
  flashdeals: state.flashdeal.data,
  currencies: state.currency.data,
  lang: state.language.language
})

const mapDispatchToProps = dispatch => ({
  getFlashDeal: () => dispatch(FlashDealActions.getFlashDeal())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

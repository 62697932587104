import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom';
// import { Link, animateScroll as scroll } from "react-scroll";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown
} from 'reactstrap';
import { connect } from 'react-redux'
// import Dropdown from 'react-dropdown'
// import 'react-dropdown/style.css'
import MenuActions from '../Redux/MenuRedux'
import LanguageActions from '../Redux/LanguageRedux'
import ReactCountryFlag from "react-country-flag";

import './css/header.css'

import logo from '../assets/images/siamexchangekk-logo.png'
// import { firebaseConnect, isEmpty } from 'react-redux-firebase'

const INITIAL_VALUE = {
  value: 'User',
  label: 'User'
}

class Header extends Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.toggle = this.toggle.bind(this)
    this.langSelect = this.langSelect.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  state = {
    profileValue: INITIAL_VALUE,
    isOpen: false,
    dropdownOpen: false,
    windowWidth: 0,
    windowHeight: 0,
  }

  componentDidMount() {
    // this.props.getMenu()
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.menu && newProps.menu) {
      console.log(newProps.menu)
    }
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }


  changeLanguage(e) {
    console.log(e)
    this.props.setLanguage(e)
  }

  toggleNavbar() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  langSelect(e) {
    this.props.setLanguage(e)
  }

  getLogoCountry = (c) => {
    return (
      <span>
        <ReactCountryFlag
          styleProps={{
            width: '1em',
            height: '1em',
            backgroundSize: '1.45em',
            fontSize: '1.4em',
            lineHeight: '1em',
            borderRadius: '50%'
          }}
          code={c}
          svg
        />
      </span>
    )
  }

  render() {

    var lang = this.props.lang
    // var lang = 'en'
    // let list = this.props.data ? this.props.data.filter(e => e.id != 3) : []
    let list = [

      {
        id: 2,
        link: '/converter',
        name_menu: {
          en: 'CONVERTER',
          th: 'แปลงค่าเงิน'
        }
      },
      {
        id: 3,
        link: '/rates',
        name_menu: {
          en: 'RATES',
          th: 'เรทแลกเงิน'
        }
      },
      {
        id: 1,
        link: '/news',
        name_menu: {
          en: 'NEWS',
          th: 'ข่าว'
        }
      },
      {
        id: 4,
        link: '/aboutus',
        name_menu: {
          en: 'ABOUT US',
          th: 'เกี่ยวกับเรา'
        }
      }
    ]
    const EN_LOGO = this.getLogoCountry('GB')
    const TH_LOGO = this.getLogoCountry('TH')

    return (

      <header className="navigation" >



        {/* <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
            <span className="navbar-toggler-icon"></span>
          </NavbarToggler> */}

        <Navbar expand="lg" className="menu">
          <Container className='navbar-header'>
            {/* <div className="container navbar-header"> */}
            <NavbarBrand href="/#">
              <img src={logo} className="logo" />
            </NavbarBrand>

            <div style={{ flexDirection: 'row-reverse', display: 'flex' }}>
              <NavbarToggler onClick={() => this.toggleNavbar()} className="navbar-toggle minus collapsed">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </NavbarToggler>

              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <Collapse isOpen={this.state.isOpen} navbar id="navbar-collapse-minus" style={{
                  left: 0, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.88)'
                }}>
                  <Nav className="ml-auto" navbar>

                    {list.map((e) => {

                      // console.log(e.id + e.link)
                      let id = 'link-'.concat(e.link)
                      return <NavItem key={e.id + e.link}>
                        {/* <NavLink> */}
                        {/* <Link
                            id={id}
                            activeClass="menu-active navlink"
                            to={e.link}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                          >
                            {e.name_menu[lang]}
                          </Link> */}
                        <Link to={e.link} style={this.state.windowWidth < 992 ? {
                          fontSize: 20,
                          padding: '5px 0'
                        } : {}
                        }>
                          {e.name_menu[lang]}
                        </Link>
                        {/* </NavLink> */}
                      </NavItem>
                    })
                    }


                  </Nav>
                </Collapse>
                <div>
                  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} >
                    <DropdownToggle caret color="light">
                      {this.props.lang === 'th' ? TH_LOGO : EN_LOGO}{` `}
                      {this.props.lang.toUpperCase()}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => this.langSelect('th')}>{TH_LOGO}{` `}TH</DropdownItem>
                      <DropdownItem onClick={() => this.langSelect('en')}>{EN_LOGO}{` `}EN</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>

            {/* <div className="list-lang container">
              <ul>
                <button className="lang" onClick={() => this.changeLanguage('th')}>ไทย</button>
                <a style={{ marginLeft: 20, marginRight: 20 }}>|</a>
                <button className="lang" onClick={() => this.changeLanguage('en')}>ENG</button>
              </ul>
            </div> */}
            {/* </div> */}
          </Container>
        </Navbar>
        <div className="separador"></div>
      </header >
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.menu.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({
  getMenu: () => dispatch(MenuActions.requestMenu()),
  setLanguage: (lang) => dispatch(LanguageActions.changeLanguage(lang))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header)

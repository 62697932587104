import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap';
import iphon from '../assets/images/Siam-Exchange-iPhoneX.png';
import google from '../assets/images/google-icon.png';
import app from '../assets/images/applestore-icon.png';

import AboutUsActions from '../Redux/AboutUsRedux'


import '../Layouts/css/about.css'
class AboutUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.props.getAboutUs()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.data != newProps.data) {
      console.log(newProps.data)
    }
  }
  handleChange = (event) => {
    this.props.set(event.target.value)
  }

  render() {

    // let content_aboutus = this.props.data && this.props.data.length > 0 ? JSON.parse(this.props.data[0]['content_aboutus']) : { en: '', th: '', zh: '' }

    // let head_aboutus = this.props.data && this.props.data.length > 0 ?
    //   JSON.parse(this.props.data[0]['head_aboutus']) : { en: '', th: '', zh: '' }
    let lang = this.props.lang
    let content_aboutus = this.props.data && this.props.data.length > 0 ? this.props.data[0][`text_${lang}`] : ''
    let head_aboutus = this.props.data && this.props.data.length > 0 ? this.props.data[0][`header_${lang}`] : ''
    console.log(content_aboutus, head_aboutus)

    return (
      <div className="outAboutus">
        <Container className="aboutus" id="about_us">
          <h1 />
          <div style={{ width: '100%' }}>
            <h2 dangerouslySetInnerHTML={{ __html: head_aboutus ? head_aboutus.toUpperCase() : '' }} />
            {/* <div className="subtitle">Fresh news everyday</div> */}
          </div>
          <div className="row">
            <div className="contentabout" dangerouslySetInnerHTML={{ __html: content_aboutus }} />

            {/* <div className="col-lg-6 col-md-6 pic">
              <img src={iphon} style={{ paddingBottom: 50 }} height={300} />
              <Row >
                <a href="https://play.google.com/store/apps/details?id=com.siamexchange" className="pic2" style={{ marginRight: -50 }} >
                  <img src={google} className="inapp" />
                </a>
                <a href="#" className="pic2">
                  <img src={app} className="inapp" />
                </a>
              </Row>
            </div> */}
          </div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.aboutus.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({
  getAboutUs: () => dispatch(AboutUsActions.requestAboutUs()),
  set: (text) => dispatch(AboutUsActions.textAboutUs(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)


import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Container, Row, Col } from 'reactstrap';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { MdPrint } from 'react-icons/md';
import { IconContext } from "react-icons";

import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/th'
import 'moment/locale/zh-cn'

import CurrencyActions from '../Redux/CurrencyRedux'

import { strictEqual } from 'assert';

import img from '../assets/images/siamexchangekk-logo.png';
import '../Layouts/css/currency.css'

import ReactCountryFlag from "react-country-flag";

const lang = {
  th: {
    currency: 'สกุลเงิน',
    buying: 'ราคาซื้อ',
    selling: 'ราคาขาย',
    lastupdate: 'อัพเดทเมื่อ',
    print: 'พิมพ์'
  },
  en: {
    currency: 'Currency',
    buying: 'Buying',
    selling: 'Selling',
    lastupdate: 'Last Update',
    print: 'print'
  },
  zh: {
    currency: '外币',
    buying: '买价',
    selling: '卖价',
    lastupdate: '最後更新',
    print: '打印'
  }
}

class Currency extends Component {

  constructor(props) {
    super(props)
    this.state = {
      menu1: 'Currency',
      menu2: 'Buying',
      menu3: 'Selling',
      lastupdate: 'Last Update',
      print: 'print',
      date: '',
      time: ''
    }
    this.props.getCurrency()
  }

  componentDidMount() {
    this.setTextByLang(this.props.lang)
  }

  componentWillReceiveProps(newProps) {
    if (this.props.lang != newProps.lang) {
      this.setTextByLang(newProps.lang)
    }

    let { date, time } = this.calDate(newProps)
    this.setState({ date, time })
  }

  setTextByLang(ll) {
    this.setState({
      menu1: lang[ll].currency,
      menu2: lang[ll].buying,
      menu3: lang[ll].selling,
      lastupdate: lang[ll].lastupdate,
      print: lang[ll].print
    })
  }

  countryFormatter(cell) {
    // console.log(cell)
    // var json = JSON.parse(cell)
    return (
      <div style={{ color: "black", fontWeight: "bold", fontSize: 20 }} dangerouslySetInnerHTML={{ __html: cell }
      } />
    );
  }

  picturePriceFormatter(cell) {
    // console.log(cell)
    // var res = "http://siamexchange.co.th/".concat(cell)
    // return (
    //   <span>
    //     <img src={res} className="flagimg" />
    //   </span>
    // );
    if (!cell) {
      return
    }
    let c = ''
    if (cell.startsWith('RUPEE')) c = 'IN'
    else if (cell == 'RUPIAH') c = 'ID'
    else {
      c = cell.substr(0, 2)
      if (c == 'JY') c = 'JP' // Japan
      else if (c == 'PI') c = 'PH' // Philipines
      else if (c == 'TU') c = 'TR' // Turkey
    }

    // console.log(c)

    return (
      <div>
        <ReactCountryFlag
          styleProps={{
            width: '30px',
            height: '30px'
          }}
          code={c}
          svg
        />
      </div>
    )
  }

  currencyFormatter(cell) {
    return <div style={{ fontSize: 18, fontWeight: 'bold', color: "rgb(130, 130, 130)", }}>
      {cell}
    </div>
  }

  currencyFormatter2(cell, row) {
    // var country = JSON.parse(row.country_name)
    // console.log(row)
    var country = row.currency_name
    return <div >
      <div style={{ color: "black", fontWeight: "bold" }}>{row.currency_name}<br /></div>
      <div style={{ fontSize: 15, color: "rgb(130, 130, 130)", fontWeight: "bold" }}>{country['en']}</div>
    </div>
  }


  sellingFormatter(cell, row) {
    let createID = 'selling'.concat(row.currency)
    return (
      <div id={createID} style={{ paddingRight: 10, height: '100%' }}>
        <span className="buyingFormatter" style={{ color: "#F7941C", fontWeight: "bold" }}>{cell}</span>
      </div>
    );
  }

  buyingFormatter(cell, row) {
    let createID = 'buying'.concat(row.currency)
    return (
      <div id={createID} style={{ paddingRight: 10 }}>
        <span className="buyingFormatter" style={{ color: "#F7941C", fontWeight: "bold" }} >{cell}</span>
      </div>
    );
  }

  countryHeaderFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filterElement}
      </div>
    );
  }

  priceFormatter() {
    return
  }

  calDate(props) {

    const max = props.data ? props.data.reduce(function (prev, current) {
      return (prev.updated_at > current.updated_at) ? prev : current
    }) : null
    let date = ''
    let time = ''
    if (max) {
      const dt = max.updated_at.split('T')
      // date = dt[0]
      // time = dt[1]
      let lang = props.lang == 'zh' ? 'zh-cn' : props.lang
      moment.locale(lang)
      time = moment(max.updated_at).format('llll')
    }

    // date = moment(date).format('ll')

    return { time }
  }



  render() {
    // console.log(this.props.data)
    // var update = this.props.data[0]["updated_at"]
    // var date = update.split(" ")
    // var splitDate = date[0].split("-")
    // var month = this.formatDate(splitDate[2])
    let { date, time, print } = this.state
    const { SearchBar } = Search;

    const columns = [
      // {
      //   dataField: 'id',
      //   text: 'Product ID'
      // },
      {
        dataField: 'currency_name',
        text: '',
        align: 'right',
        headerClasses: 'price',
        formatter: this.picturePriceFormatter,
      },
      {
        dataField: 'country_name',
        text: '',
        headerClasses: 'price-header-normal',
        formatter: this.countryFormatter,
        classes: 'colunm-table'
      },
      {
        dataField: 'currency_name',
        text: this.state.menu1,
        formatter: this.currencyFormatter,
        headerClasses: 'price-header-normal',
        classes: 'colunm-table-currency'
      },
      {
        dataField: 'currency',
        text: this.state.menu1,
        formatter: this.currencyFormatter2,
        headerClasses: 'price-header-medie',
        classes: 'colunm-table-medie'
      },
      {
        dataField: 'buying',
        text: this.state.menu2,
        formatter: this.buyingFormatter,
        headerClasses: 'price-header'
      },
      {
        dataField: 'selling',
        text: this.state.menu3,
        formatter: this.sellingFormatter,
        headerClasses: 'price-header'
      }

    ];


    let { data } = this.props

    // console.log(data)

    if (this.props.limit) {
      data = data.filter(e => Number.isInteger(e.order))
      data = data.filter((e, i) => i < this.props.limit)
    }

    // console.log(data)

    let menu = {
      name_menu: {
        en: 'EXCHANGE RATES',
        th: 'อัตราแลกเปลี่ยน'
      }
    }

    menu = menu['name_menu'][this.props.lang]

    return (
      <IconContext.Provider value={{ className: 'react-icons' }}>

        <div className="rate_table_wrapper" id="section-to-print">
          <Container>

            <div className="row currency" id="rates">
              <div style={{ width: '100%' }}>
                <h2>{menu}</h2>
                <div className="subtitle">Check fresh rate everyday</div>
              </div>
              <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search
              >
                {
                  props => (
                    <div className='tablecurrency'>

                      <Row>
                        <Col md="4">
                          <img src={img} style={{ height: 50, marginLeft: 30 }} className="logoprint" />
                          {/* {!this.props.limit && <SearchBar {...props.searchProps} />} */}
                        </Col>
                        <Col md="8" style={{ textAlign: 'right' }}>
                          <span className="update">{this.state.lastupdate} :  {time} {/*print*/}
                            {/* <img
                              src="http://siamexchange.co.th/images/icon-printer.svg"
                              className="img-printer"
                              alt=""
                              onClick={this.exportPDFWithComponent} /> */}

                            {/* <MdPrint onClick={this.exportPDFWithComponent} className="img-printer" size="2em" /> */}

                          </span>
                        </Col>
                      </Row>

                      <BootstrapTable
                        {...props.baseProps}
                        rowClasses={'custom-row-class'}
                        // striped
                        hover
                        bordered={false}
                        condensed
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
            </div>
          </Container>
        </div>
      </IconContext.Provider>
    )
  }
  exportPDFWithComponent = () => {
    window.print();
  }

}

const mapStateToProps = (state) => ({
  country: state.currency.country,
  data: state.currency.data,
  lang: state.language.language
});

const mapDispatchToProps = (dispatch) => ({
  getCurrency: () => dispatch(CurrencyActions.requestCurrency()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Currency)


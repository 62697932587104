import { call, put } from 'redux-saga/effects'
import ContactActions from '../Redux/ContactRedux'

export function* requestContact(api) {
  const response = yield call(api.getContact)

  console.log(response)

  if (response.ok) {
    yield put(ContactActions.requestContactSuccess(response.data[0]))
  }
}
